import React, { useState, useEffect } from 'react'
import { withFilter } from '../context/Filter'
import { withProfile } from '../context/Profile'
import http from '../http'
import moment from 'moment'
import Comatize from './Comatize'
import getHash from '../hooks/getHash'

function Fetch( props ) {
  let {
    query = {}
  } = props

  const {
    type,
    endpoint,
    params = {},
    useFilter = false,
    method = 'POST',
    reload = 0
  } = props

  const {
    model = {},
    steps = []
  } = props.withFilter.state

  const [ hash, setHash ] = useState()
  const [ data, setData ] = useState()
  const [ date, setDate ] = useState( defaultDate() )
  const { profile } = props.withProfile
  const [ oldModelHash, setOldModelHash ] = useState( getHash( model ) )

  // before
  let before = props.before || {}
  if ( before instanceof Array === false )
    before = [ before ]

  let stringifiedParams = JSON.stringify( params )

  // this week
  if ( stringifiedParams.indexOf( 'FN_THIS_WEEK' ) !== -1 ) {
    const thisWeek = getThisWeek()
    stringifiedParams = stringifiedParams.replaceAll( '"FN_THIS_WEEK"', JSON.stringify( thisWeek ) )
  }

  // last week
  if ( stringifiedParams.indexOf( 'FN_LAST_WEEK' ) !== -1 ) {
    const lastWeek = getLastWeek()
    stringifiedParams = stringifiedParams.replaceAll( '"FN_LAST_WEEK"', JSON.stringify( lastWeek ) )
  }

  // date
  if ( stringifiedParams.indexOf( 'FN_DATE' ) !== -1 ) {
    stringifiedParams = stringifiedParams.replaceAll( '"FN_DATE"', JSON.stringify( {
      $gte: date.from,
      $lte: date.to
    } ) )
  }

  useEffect( () => {
    const { from, to } = model

    if ( ! from && ! to )
      setDate( defaultDate() )

    if ( ! to )
      return true

    setDate( { from, to } )
  }, [ model ] )

  useEffect( () => {
    let body = [ JSON.parse( stringifiedParams ) ]

    if ( useFilter ) {
      body = [ ...steps, ...body ]
    }

    // HARDCODE filter by owner
    const username = Number( profile.username )
    if ( ! Number.isNaN( username ) && username !== 0 ) {
      body = [
        {
          __external: {
            from: 'datos',
            localField: 'obra_interno',
            foreignField: 'IdObraInternoCEDRO',
            match: { IdTitular: username },
            unique: true
          }
        },
        ...body
      ]
    }

    // HARDCODE filter by status
    body = [
      ...before,
      ...body
    ]

    const newHash = getHash( { body, query, reload } )

    if ( newHash === hash )
      return

    setHash( newHash )

    // set offset to 0
    const newModelHash = getHash( model )
    if ( newModelHash !== oldModelHash ) {
      const hasQuery = Object.keys( query ).length !== 0

      if ( hasQuery )
        query = { ...query, offset: 0 }

      setOldModelHash( newModelHash )
    }

    http( {
      url: endpoint,
      method,
      params: query,
      data: body
    } )
      .then( response => {
        setData( response.data )
      } )
  }, [
    endpoint,
    method,
    steps,
    useFilter,
    query,
    stringifiedParams,
    hash,
    reload
  ] )

  function defaultDate() {
    return {
      from: moment().subtract( 1, 'month' ).format( 'YYYY-MM-DD' ),
      to: moment().format( 'YYYY-MM-DD' )
    }
  }

  function getThisWeek() {
    const from = moment( date.to, 'YYYY-MM-DD' )
      .subtract( 6, 'days' )
      .format( 'YYYY-MM-DD' )

    const to = date.to

    return {
      $gte: from,
      $lte: to
    }
  }

  function getLastWeek() {
    const from = moment( date.to, 'YYYY-MM-DD' )
      .subtract( 13, 'days' )
      .format( 'YYYY-MM-DD' )

    const to = moment( from, 'YYYY-MM-DD' )
      .add( 6, 'days' )
      .format( 'YYYY-MM-DD' )

    return {
      $gte: from,
      $lte: to
    }
  }

  if ( ! data )
    return null

  let toRender
  switch ( type ) {
    case 'number':
      toRender = <Comatize>{ data.count }</Comatize>
      break

    case 'pie':
      toRender = props.children( data )
      break

    case 'area':
      toRender = props.children( data )
      break

    case 'table':
      toRender = props.children( data )
      break

    default:
      toRender = props.children( data )
  }

  return (
    <>
      { toRender }
    </>
  )
}

export default withFilter( withProfile( Fetch ) )
